/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $WritePost = {
    properties: {
        messages: {
    type: 'array',
    contains: {
        type: 'Message',
    },
    isRequired: true,
},
        create: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
        writeHash: {
    type: 'string',
    isRequired: true,
},
    },
} as const;
