import './MessageBox.css';
import {useCallback, useState} from "react";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const sendMail = async (text: string) => {
    const url = new URL('https://api.whistleblower.kivi.bz.it/sendmail');
    url.searchParams.append('text', text);

    const headers = {
        'Accept': 'application/json'
    };

    try {
        const response = await fetch(url.toString(), {
            method: 'POST',
            headers: headers,
            body: '' // Empty body, assuming this is still required
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error('Error sending mail:', error);
    }
};

type MessageBoxProps = {
    onSubmit: (message: string) => void
}

const MessageBox: React.FC<MessageBoxProps> = ({onSubmit}) => {
    const [message, setMessage] = useState<string>('');

    const onSendButtonClicked = useCallback(() => {
        sendMail(message).then(() => {
            console.log("Message sent!",);
            onSubmit(message);
        });
    }, [message]);

    return (
        <div>
            <div className="messageForm">
                <textarea onChange={(e) => setMessage(e.target.value)} className="input-area"/>
                <button value={message} onClick={onSendButtonClicked} className="sendButton">Invia | Senden</button>
                <ToastContainer theme="light"/>
            </div>
        </div>
    );
}


export {MessageBox};