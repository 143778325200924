/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostCreate } from '../models/PostCreate';
import type { ReadPost } from '../models/ReadPost';
import type { ResponsePostCreate } from '../models/ResponsePostCreate';
import type { WritePost } from '../models/WritePost';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Root
     * @returns any Successful Response
     * @throws ApiError
     */
    public static rootGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

    /**
     * Create Post
     * @param requestBody 
     * @returns ReadPost Successful Response
     * @throws ApiError
     */
    public static createPostAPost(
requestBody: PostCreate,
): CancelablePromise<ReadPost> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/a',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Post
     * @param readHash 
     * @returns ReadPost Successful Response
     * @throws ApiError
     */
    public static readPostAReadHashGet(
readHash: string,
): CancelablePromise<ReadPost> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/a/{read_hash}',
            path: {
                'read_hash': readHash,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Post
     * @param writeHash 
     * @returns WritePost Successful Response
     * @throws ApiError
     */
    public static readPostKWriteHashGet(
writeHash: string,
): CancelablePromise<WritePost> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/k/{write_hash}',
            path: {
                'write_hash': writeHash,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Post
     * @param writeHash 
     * @param requestBody 
     * @returns WritePost Successful Response
     * @throws ApiError
     */
    public static createPostKWriteHashPost(
writeHash: string,
requestBody: ResponsePostCreate,
): CancelablePromise<WritePost> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/k/{write_hash}',
            path: {
                'write_hash': writeHash,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
