// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.message {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    width: 40vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.message-sender {
    align-self: flex-start;
    margin-bottom: 10px;
}

.message-content {
    align-self: flex-end;
    width: 100%;
}

.message.anonymous {
    align-self: flex-start;
    background: white;
}

.message.kronservice {
    align-self: flex-end;
    background: #4faf98;
}

.message.anonymous .message-sender {
    text-align: left;
}

.message.kronservice .message-sender {
    text-align: right;
}


.message.anonymous .message-content {
    text-align: left;
}


.message.kronservice .message-content {
    text-align: right;
}

`, "",{"version":3,"sources":["webpack://./src/MessageElement.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,WAAW;IACX,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,gBAAgB;AACpB;;;AAGA;IACI,iBAAiB;AACrB","sourcesContent":["\r\n.message {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin: 20px;\r\n    padding: 20px;\r\n    width: 40vw;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n    border-radius: 10px;\r\n}\r\n\r\n.message-sender {\r\n    align-self: flex-start;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.message-content {\r\n    align-self: flex-end;\r\n    width: 100%;\r\n}\r\n\r\n.message.anonymous {\r\n    align-self: flex-start;\r\n    background: white;\r\n}\r\n\r\n.message.kronservice {\r\n    align-self: flex-end;\r\n    background: #4faf98;\r\n}\r\n\r\n.message.anonymous .message-sender {\r\n    text-align: left;\r\n}\r\n\r\n.message.kronservice .message-sender {\r\n    text-align: right;\r\n}\r\n\r\n\r\n.message.anonymous .message-content {\r\n    text-align: left;\r\n}\r\n\r\n\r\n.message.kronservice .message-content {\r\n    text-align: right;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
