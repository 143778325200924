import React, {useCallback, useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {MessageBox} from "./MessageBox";
import {DefaultService, OpenAPI, PostCreate, ReadPost} from "./client/api";
import {createReadPostUrl} from "./post_urls";
import {toast} from "react-toastify";
import {SenderType} from "./enums";
import PostElement from "./Post";


function App() {
    const [senderType, setSenderType] = useState<SenderType | null>(null);
    const [hash, setHash] = useState<string | null>(null);


    const onSubmit = useCallback((message: string) => {
        const postCreate: PostCreate = {
            message,
        }
        DefaultService.createPostAPost(postCreate).then((readPost: ReadPost) => {
            window.location.href = createReadPostUrl(readPost.readHash);
        }).catch((error: any) => {
            toast("Errore durante l'invio del messaggio | Fehler beim Senden der Nachricht");
        });
    }, []);

    useEffect(() => {
        OpenAPI.BASE = 'https://api.whistleblower.kivi.bz.it';
        const path = window.location.pathname;

        const splitPath = path.split('/')

        console.log(splitPath);

        if (splitPath.length < 2) {
            return;
        }

        setHash(splitPath.pop()!)
        switch (splitPath.pop()!) {
            case "a":
                setSenderType(SenderType.ANONYMOUS);
                break;
            case "k":
                setSenderType(SenderType.KRONSERVICE);
                break;
            default:
                console.error("Unknown sender type");
        }

    }, []);

    return (
        <div className="App">
            <img src={logo} className="App-logo" alt="logo"/>
            {
                senderType != null && hash != null ? (
                    <PostElement hash={hash} sender={senderType}></PostElement>
                ) : (<>
                        <h2>Strumento per whistleblower della Kronservice SRL</h2>
                        <h2>Whistleblower-Tool der Kronservice GmbH</h2>
                        <div className="text-div">
                            Questo sito web funge da strumento whistleblower per la Kronservice GmbH. I messaggi che
                            inserite in
                            questo campo di testo verranno inviati via email al personale competente. Non verranno
                            creati file di
                            log che possano rivelare la vostra identità. Se tuttavia non fidatevi del sito, vi
                            consigliamo di
                            accedervi da un computer pubblico, ad esempio in una biblioteca.
                        </div>
                        <div className="text-div">
                            Diese Webseite dient als Whistleblower-Tool für die Kronservice GmbH. Nachrichten, die Sie
                            in dieses
                            Textfeld eingeben, werden per E-Mail an das zuständige Personal gesendet. Es werden keine
                            Logdateien
                            erstellt, die Rückschlüsse auf Ihre Identität zulassen könnten. Sollten Sie der Seite
                            dennoch
                            misstrauen, empfehlen wir, sie von einem öffentlichen Computer aus zu nutzen, beispielsweise
                            in einer
                            Bibliothek.
                        </div>
                        <MessageBox onSubmit={onSubmit}/>
                    </>
                )
            }

        </div>
    );
}


export default App;
