// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.message-container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    width: 60vw;
}

.message-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-text {
    margin-top: 10px;
    padding: 10px;
    background: white;
}
`, "",{"version":3,"sources":["webpack://./src/Post.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["\r\n.message-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin: 20px;\r\n    padding: 20px;\r\n    width: 60vw;\r\n}\r\n\r\n.message-root {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n}\r\n\r\n.info-text {\r\n    margin-top: 10px;\r\n    padding: 10px;\r\n    background: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
