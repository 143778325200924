import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import translationDE from './locales/de/translation.json';
import translationIT from './locales/it/translation.json';

const resources = {
    de: {
        translation: translationDE
    },
    es: {
        translation: translationIT
    }
};


i18n.use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'de', // use 'en' if the specified language translations are not available
        interpolation: {
            escapeValue: false
        }
    });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
            <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
