import {Message} from "./client/api";
import './MessageElement.css'
import {useMemo} from "react";
import DOMPurify from "dompurify";

type MessageProps = {
    message: Message;
}


const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

const MessageElement: React.FC<MessageProps> = ({message}) => {
    const className = useMemo(() => {
        switch (message.sender) {
            case "Anonymous":
                return "message anonymous";
            case "Kronservice":
                return "message kronservice";
            default:
                return "message";
        }
    }, []);

    const sanitizedMessage: string = DOMPurify.sanitize(message.message, {ALLOWED_TAGS: ['br']});

    return (<div className={className}>
        <div className="message-sender">
            {message.sender}: {formatDate(message.create)}
        </div>
        <div className="message-content" dangerouslySetInnerHTML={{__html: sanitizedMessage.replace(/\n/g, '<br/>')}}/>
    </div>)
}

export default MessageElement;
