/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Message = {
    properties: {
        anonymous: {
    type: 'boolean',
    isRequired: true,
},
        sender: {
    type: 'string',
    isRequired: true,
},
        message: {
    type: 'string',
    isRequired: true,
},
        id: {
    type: 'number',
    isRequired: true,
},
        create: {
    type: 'string',
    isRequired: true,
    format: 'date-time',
},
    },
} as const;
