import {SenderType} from "./enums";
import React, {useCallback, useEffect, useState} from "react";
import {DefaultService, Message, ReadPost, ResponsePostCreate, WritePost} from "./client/api";
import MessageElement from "./MessageElement";
import "./Post.css";
import {MessageBox} from './MessageBox';

type PostProps = {
    sender: SenderType;
    hash: string;
}


const PostElement: React.FC<PostProps> = ({sender, hash}) => {
    const [messages, setMessages] = useState<Message[]>([]);

    useEffect(() => {
        switch (sender) {
            case SenderType.ANONYMOUS:
                DefaultService.readPostAReadHashGet(hash).then((post: ReadPost) => {
                    setMessages(post.messages!);
                }).catch((error: any) => {
                    console.error(error);
                });
                break;
            case SenderType.KRONSERVICE:
                DefaultService.readPostKWriteHashGet(hash).then((post: WritePost) => {
                    setMessages(post.messages!);
                }).catch((error: any) => {
                    console.error(error);
                });
                break;
            default:
                console.error("Unknown sender type");
                window.location.href = "/";
        }

    }, []);

    const onMessageBoxSubmit = useCallback((message: string) => {
        const writePost: ResponsePostCreate = {
            message,
            sender: "Kronservice"
        }
        DefaultService.createPostKWriteHashPost(hash, writePost).then((_) => {
            window.location.reload();
        }).catch((error: any) => {
            console.error(error);
        });

    }, []);



    return (
        <div className="message-root">
            <div className="info-text">
                Copia l'URL nella barra degli indirizzi per poter accedere a questa pagina ancora e ancora. <br/>
                Entro 7 giorni riceverai in questa pagina la conferma che il messaggio è stato letto.<br/>
                Riceverai una risposta alla tua richiesta entro 90 giorni.<br/><br/>
                Bitte kopieren Sie sich die URL in der Adresszeile um immer wieder auf diese Seite zugreifen zu können. <br/>
                Innerhalb 7 Tagen wird ihnen auf dieser Seite bestätigt, dass die Nachricht gelesen wurde.<br/>
                Innerhalb von 90 Tagen werden Sie eine Antwort auf Ihr Anliegen erhalten.<br/>
            </div>

            <div className="message-container">
                {messages.map((message) => <MessageElement message={message}/>)}
            </div>
            {
                (sender === SenderType.KRONSERVICE) ? (
                    <div className="input-container">
                        <MessageBox onSubmit={onMessageBoxSubmit}/>

                    </div>) : (<></>)
            }
        </div>
    )
}


export default PostElement;
